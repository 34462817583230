import { Tag } from 'antd'
import React from 'react'

interface OwnProps {
  status: string
}

function statusToColor (status: string): string {
  switch (status) {
  case 'pending': return 'gold'
  case 'processing': return 'lime'
  case 'challenged': return 'volcano'
  case 'declined': return 'red'
  case 'error': return 'magenta'
  case 'cancelled': return 'red'
  case 'paid': return 'green'
  case 'retry': return 'purple'
  case 'overdue': return 'blue'
  default: return 'red'
  }
}

type Props = OwnProps & React.PropsWithChildren

const InvoiceTag = function (props: Props): React.ReactElement {
  const finalColor: string = statusToColor(props.status)
  return (<Tag color={finalColor}>{props.children}</Tag>)
}

export default InvoiceTag
