export const LIVENESS_SUCCESS_MESSAGE = `
  <b> Prezado Operador Informamos que o cliente foi aprovado! </b>
  <br/> <br/>

  Por favor, siga com o procedimento padrão de check-list ao 
  lado do cliente, <b> revisando cada item com atenção </b> 
  para garantir uma entrega segura e completa.

  <br/> <br/>

  Agradecemos pelo compromisso com a qualidade no atendimento!
`

export const LIVENESS_NO_GTS_REGISTER = `
  Reconhecimento facial indisponível.
  Não foi encontrado nenhum registro para o bookingId
`

export const LIVENESS_WAINTING = `
  Liveness foi criado com sucesso! </br>
  Basta selecionar o último registro, clicar em "Abrir QRCode" e seguir o passo a passo.
`


export const LIVENESS_NO_GTS_REGISTER_FOR_CURRENT_DATE = `
  Realize o processo de reconhecimento facial clicando em: Iniciar reconhecimento
  E siga as instruções.
`

export const LIVENESS_UNAVAILABLE_BY_TIME = `
  Reconhecimento facial estará apenas disponível 
  para bookings entre 2hrs antes do inicio do mesmo 
  e até 2horas após o inicio disponibilizado no GTS
`

export const LIVENESS_ONE_ERROR_MESSAGE = 'Cliente reprovado, tentar novamente.'

export const LIVENESS_TWO_ERROR_MESSAGE = `
  Cliente reprovado pela segunda vez. Avaliar se todos os 
  critérios expostos no inicio do processo estão sendo 
  seguidos e tentar novamente.
`
export const LIVENESS_REPROVED_MESSAGE = `
  <b> O processo de selfie do cliente não foi concluído com sucesso. </b>

  <br/> <br />

  <b> Nossa recomendação é de que a entrega não seja realizada e o booking 
  cancelado; </b> porém, se houver confiança de que se trata de um erro do sistema, 
  e se for possível validar presencialmente que o cliente é, de fato, o titular da 
  CNH cadastrada, a entrega poderá ser concluída conforme seu julgamento sob 
  responsabilidade da concessionária. 

  <br/> <br />
  
  Registre as informações e uma foto da CNH do cliente para formalização do erro 
  junto ao SAC através do e-mail: suporte@kintomobility.com.br
  
  <br/> <br />
  
  Agradecemos pela compreensão e suporte.
`