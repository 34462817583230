import CollapseLabelItem from 'components/atomic/molecules/collapseLabelItem'
import React from 'react'

import { antDesignCollapseItemProps, antDesignCollapseItemResponseProps } from './types'

export const generateCollapseItemList = <TData extends unknown>({
  data,
  component,
  label,
  tagColor,
  tagLabel,
}: antDesignCollapseItemProps<TData>) => {
  if(!data) return []

  return data.map<antDesignCollapseItemResponseProps>((item, idx) => ({
    key: idx,
    label: React.createElement(CollapseLabelItem, 
      { 
        label: label(item), 
        tagColor: tagColor?.(item), 
        tagLabel: tagLabel(item) 
      }
    ),
    children: component(item),
  }))
}