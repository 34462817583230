import dayjs, { type ConfigType, type Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { DateFormatTypes, DateTimeZones } from './enums'
import { DateOptionsProps } from './types'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)

dayjs.locale('pt')

class DateHelper {
  private _date: Dayjs = dayjs('')

  constructor(date?: ConfigType, options?: DateOptionsProps) {
    const timeZone = options?.timeZone ?? DateTimeZones.saoPaulo
    const currentDate = dayjs(date) ?? dayjs(new Date())

    this._date = dayjs.tz(currentDate, timeZone)
  }
    
  public setDate = (data: ConfigType) => {
    this._date = dayjs.tz(data)
    
    return this
  }

  public diffDate = (date: ConfigType) => 
    dayjs(date).diff(this._date, 'hours')

  public formatDate = (format: DateFormatTypes) => 
    this._date.format(format)
}

export default DateHelper