import { type FetchDataProps } from "./types"

const FetchData = <TData extends unknown>({
    hasData,
    isLoading,
    children,
    error,
}: FetchDataProps<TData>) => {

    if(error && !isLoading && !hasData) return <p>Erro ao carregar os dados</p>

    if(!error && !!isLoading && !hasData) return <p>Carregando...</p>

    return <>{children}</>
}

export default FetchData