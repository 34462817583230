import { type AxiosError } from "axios"
import { call, put } from "redux-saga/effects"
import { type PayloadAction } from "typesafe-actions"

import GTS from "../../../apis/GTS"
import { searchBookingFailure, searchBookingSuccess } from "./actions"
import { type Booking, type BookingsTypes } from "./types"

export function * load (action: PayloadAction<BookingsTypes, { bookingId: string }>): Generator {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = yield call(GTS.bookingData, action.payload.bookingId)
    const localId = localStorage.getItem("@kinto-solutions/gts/id")
    const localEmail = localStorage.getItem("@kinto-solutions/gts/email")

    const responseEmail = response._embedded.user.email
    const responseId = response._embedded.user.id

    if (String(responseEmail).toLowerCase() !== localEmail || String(responseId) !== localId) {
      window.location.href = "/"
    }

    yield put(searchBookingSuccess(response as Booking))
  } catch (err: any) {
    const errorCode = err.response.data.error_code

    if (errorCode === "INVALID_BOOKING_ID") {
      window.location.href = "/"
    }

    let errorMessage: string = "Ocorreu um erro, tente novamente mais tarde!"
    if ((err as AxiosError).isAxiosError) {
      errorMessage = (err as AxiosError<{ message: string }>).response?.data?.message ?? ""
    } else if (err instanceof Error) {
      errorMessage = err.message
    }
    yield put(searchBookingFailure(errorMessage))
  }
}
