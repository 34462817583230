import { type Reducer } from 'redux'

import { type LivenessState, LivenessTypes } from './types'

const INITIAL_STATE: LivenessState = {
  errorLivenessList: false,
  errorMessageNewLiveness: '',
  errorNewLiveness: false,
  livenesList: [],
  loadingLivenessList: false,
  loadingNewLiveness: false,
  newLiveness: undefined,
  livenessSuccess: false,
  errorLivenessListData: {
    message: '',
    status: -999
  }
}

const reducer: Reducer<LivenessState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case LivenessTypes.GENERATE_LIVENESS:
    return { ...state, loadingNewLiveness: true }
  case LivenessTypes.GENERATE_LIVENESS_SUCCESS:
    return { ...state, loadingNewLiveness: false, errorNewLiveness: false, newLiveness: action.payload, livenessSuccess: true }
  case LivenessTypes.GENERATE_LIVENESS_FAILURE:
    return { ...state, loadingNewLiveness: false, errorNewLiveness: true, newLiveness: action.payload, livenessSuccess: false }
  case LivenessTypes.GET_LIVENESS_LIST:
    return { ...state, loadingLivenessList: true }
  case LivenessTypes.GET_LIVENESS_LIST_SUCCESS:
    return { ...state, livenesList: action.payload, loadingLivenessList: false, errorLivenessList: false }
  case LivenessTypes.GET_LIVENESS_LIST_FAILURE:
    return { ...state,livenesList: [], loadingLivenessList: false, errorLivenessList: true, errorLivenessListData: action.payload }
  default:
    return state
  }
}

export default reducer
