import { StatusCardType } from './enum'
import { StatusCardProps } from './types'

import './styles.css'

const StatusCard = ({
  message,
  statusType = StatusCardType.Info,
  children
}: StatusCardProps) => 
  <div 
    className='container'
    arial-status-type={String(statusType)}
  >
    <p dangerouslySetInnerHTML={{ __html: message }} />

    {children && children}
  </div>

export default StatusCard