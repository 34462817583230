import { StatusCardType } from 'components/atomic/atoms/StatusCard/enum'

import { LivenessErrorCounts, LivenessStatus } from './enums'
import { 
  LIVENESS_ONE_ERROR_MESSAGE, 
  LIVENESS_REPROVED_MESSAGE, 
  LIVENESS_TWO_ERROR_MESSAGE 
} from './messages'
import { CommonDataProps } from '../../types/commons'

export const TWO_HOURS_BEFORE_PICK_UP = -2
export const TWO_HOURS_AFTER_PICK_UP = 2

export const LIVENESS_ERROR_LIMIT = 3

export const LIVENESS_LIST: CommonDataProps<LivenessStatus>[] = [
  { id: LivenessStatus.Expired, color: 'orange', label: 'Expirado' },
  { id: LivenessStatus.Failed, color: 'red', label: 'Reprovado' },
  { id: LivenessStatus.Sucess, color: 'green', label: 'Sucesso' },
  { id: LivenessStatus.InProgress, color: 'yellow', label: 'Em progresso' },
  { id: LivenessStatus.OCR, color: 'yellow', label: 'OCD' },
  { id: LivenessStatus.Wainting, color: 'yellow', label: 'Aguarde' },
  { id: LivenessStatus.Analysis, color: 'yellow', label: 'Em análise' },
]

export const LIVENESS_FACEMATCH_FEEDBACK_LIST: CommonDataProps<LivenessErrorCounts>[] = [
  { id: LivenessErrorCounts.OneError, statusCardType: StatusCardType.Warning, label: LIVENESS_ONE_ERROR_MESSAGE },
  { id: LivenessErrorCounts.TwoErros, statusCardType: StatusCardType.Warning, label: LIVENESS_TWO_ERROR_MESSAGE },
  { id: LivenessErrorCounts.ThreeErros, statusCardType: StatusCardType.Error, label: LIVENESS_REPROVED_MESSAGE },
]
