import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { Button, Collapse, Modal, Tag } from 'antd'
import image from 'assets/liveness-instructions.jpeg'
import SimboloKinto from 'assets/SimboloKinto.svg'
import { FetchData } from 'components'
import { StatusCard } from 'components/atomic/atoms'
import { StatusCardType } from 'components/atomic/atoms/StatusCard/enum'
import { useLivenessList } from 'hooks/liveness/useLivenessList'
import React, { useEffect, useRef, useState } from 'react'
import { QRCode } from 'react-qrcode-logo'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { bindActionCreators, type Dispatch } from 'redux'
import { LIVENESS_LIST } from 'services/livenessService/consts'
import { type ApplicationState } from 'store'
import { type Booking } from 'store/ducks/booking/types'
import * as LivenessActions from 'store/ducks/liveness/actions'
import { type LivenessState } from 'store/ducks/liveness/types'
import { generateCollapseItemList } from 'utils/antDesign'
import { getStaticPropsById } from 'utils/common'
import { censorEmail, censorPhone, toFormatedPlate } from 'utils/helpers'
import { texts } from 'utils/texts'
import { timeAsDayjs } from 'utils/time'
import { DateFormatTypes } from 'utils/time/enums'
import DateHelper from 'utils/time/time'


function mapStateToProps(state: ApplicationState): StateProps {
  return ({
    booking: state.bookings.data,
    liveness: state.liveness
  })
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(LivenessActions, dispatch)
}

interface StateProps {
  booking: Booking
  liveness: LivenessState
}

interface DispatchProps {
  generateLiveness: (payload: {
    bookingId: string
  }) => void

  getLivenessList: (payload: {
    bookingId?: string
    userId?: string
  }) => void
}

type Props = StateProps & DispatchProps

const LivenessView = (props: Partial<Props>) => {
  // #region Novos códigos refatorados
  const { bookingId } = useParams()
  
  const { 
    data: livenessListData,
    error: livenessListError,
    isLoading: livenessIsLoading,
    isFaceMatchButtonAvailable,
    faceMatchButtonText,
    feedbackLiveness,
    isValidating,
    revalidate,
  } = useLivenessList(
    { bookingId }, 
    props.booking?.pickUpDatetime
  )
  
  const items = generateCollapseItemList({
    data: livenessListData.livenessList,
    label: i => new DateHelper(i.createdAt).formatDate(DateFormatTypes.dateHourMinutes),
    tagColor: i => getStaticPropsById(LIVENESS_LIST, i.status)?.color,
    tagLabel: i => getStaticPropsById(LIVENESS_LIST, i.status).label,
    component: item => (
      <>
        <p>Status: <Tag color={getStaticPropsById(LIVENESS_LIST, item.status).color}>{getStaticPropsById(LIVENESS_LIST, item.status).label}</Tag></p>
        <p>Criado em: {new DateHelper(item.createdAt).formatDate(DateFormatTypes.dateHourMinutes)}</p>
        <p>Ultima Atualização em: {new DateHelper(item.updatedAt).formatDate(DateFormatTypes.dateHourMinutes)}</p>
        <p>Expira em: {new DateHelper(item.expiresAt).formatDate(DateFormatTypes.dateHourMinutes)}</p>
        {(item.status === 'WAITING' || item.status === 'IN PROGRESS') && (<Button onClick={() => { openLivenessQRCode(item._id) }}>Abrir QRCode</Button>)}
      </>
    )
  })
  // #endregion

  const [newLivenessModal, contextHolder] = Modal.useModal()
  const qrCodeRef = useRef<QRCode>(null)
  const clientFirstName = props?.booking?._embedded?.user?.personalInfo?.firstName ?? 'Não informado'
  const clientLastName = props?.booking?._embedded?.user?.personalInfo?.lastName ?? ''
  const clientFullName = `${clientFirstName} ${clientLastName}`
  const hasVehicleData = props?.booking?._embedded?.vehicle
  const hasUserData = props?.booking?._embedded?.user
  const [isOpen, setIsOpen] = useState(false)
  
  function openLivenessQRCode(id: number) {
    newLivenessModal.confirm({
      title: texts.liveness.newProcess,
      icon: <CheckCircleFilled style={{ color: 'green' }} />,
      content: <div>
        {texts.liveness.qrCodeTile}

        <QRCode
          value={`${process.env.REACT_APP_LIVENESS_URI}?id=${id}`}
          size={300}
          logoWidth={50}
          logoHeight={35}
          ecLevel='M'
          fgColor={'#00708d'}
          ref={qrCodeRef}
          logoImage={SimboloKinto}
          removeQrCodeBehindLogo
          id="pixqrcode"
        />
        <p><a href={`${process.env.REACT_APP_LIVENESS_URI}?id=${id}`} target="_blank" rel="noreferrer">{`${process.env.REACT_APP_LIVENESS_URI}?id=${id}`}</a></p>
      </div>
    })
  }

  useEffect(() => {
    if (!props?.liveness?.loadingNewLiveness) {
      if (props?.liveness?.newLiveness?._id) {
        openLivenessQRCode(props?.liveness.newLiveness._id)
      }
    }
  }, [props?.liveness?.loadingNewLiveness])

  useEffect(() => {
    if (props?.liveness?.livenessSuccess && props?.booking?.id) {
      revalidate()
    }
  }, [props?.liveness?.livenessSuccess])

  useEffect(() => {
    if (props?.liveness?.errorNewLiveness && Number(props?.liveness?.newLiveness?.status) !== 500) {
      newLivenessModal.error({
        title: 'Erro ao iniciar o Reconhecimento',
        icon: <CloseCircleFilled style={{ color: 'red' }} />,
        onOk: () => { setIsOpen(false) },
        open: isOpen,
        content: <div>
          {texts.liveness.newLivenessError?.map((text) => {
            return (
              <p>
                {text}
              </p>
            )
          })}
        </div>,
        width: 520
      })
    }
  }, [])

  useEffect(() => {
    let message, width, action, error = false
    const status = props?.liveness?.errorNewLiveness ? props?.liveness?.newLiveness?.status : props?.liveness?.errorLivenessListData?.status
    switch(status) {
    case 401:
      message = texts.general.expired 
      width = 540
      action = () => window.location.href = '/auth/booking' 
      error = true
      break
      
    case 500:
      message = texts.general.genericError 
      width = 650
      action = () => setIsOpen(false)
      error = true
      break
    }

    if(error) {
      newLivenessModal.error({
        title: message,
        icon: <CloseCircleFilled style={{ color: 'red' }} />,
        onOk: action,
        open: isOpen,
        width: width
      })
    }
  }, [props?.liveness?.errorLivenessListData, props?.liveness?.errorNewLiveness])

  const handleNewLiveness = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    if (isFaceMatchButtonAvailable) {
      props?.generateLiveness!({
        bookingId: props?.booking?.id ?? ''
      })
    }
  }

  return (
    <div className="liveness-workspace">
      <div className="liveness-card">
        <div className="liveness-card-info">
          Cliente: {clientFullName}<br />
          Data prevista:  {timeAsDayjs(props?.booking?.pickUpDatetime).subtract(3, 'hours').format('DD/MM/YYYY HH:mm')}<br />
          Veículo: {props?.booking?._embedded?.vehicle?.model?.fullName}<br />
          Placa: {toFormatedPlate(props?.booking?._embedded?.vehicle?.registrationPlate)}<br />
          E-mail: <b>{censorEmail(props?.booking?._embedded?.user?.email)}</b><br />
          Celular: <b>{censorPhone(props?.booking?._embedded?.user?.personalInfo?.mobilePhoneNumber)}</b><br />
        </div>
        <div className="liveness-card-email">
          {hasVehicleData && hasUserData &&
            <Button
              type="primary"
              disabled={!isFaceMatchButtonAvailable}
              onClick={handleNewLiveness}
              loading={props?.liveness?.loadingNewLiveness}
            >
              {faceMatchButtonText}
            </Button>}
        </div>
      </div>

      <div style={{ maxWidth: '45%' }}>
        {(!!feedbackLiveness?.message && !livenessIsLoading) && (
          <StatusCard {...feedbackLiveness}>
            {(isFaceMatchButtonAvailable && feedbackLiveness.statusType && feedbackLiveness.statusType !== StatusCardType.Info) && <img src={image} height={350} width={400}/>}
          </StatusCard>
        )}
      </div>

      <div className="liveness-history">
        <Button
          type="link"
          className="search-list"
          onClick={event => {
            event.preventDefault()
            revalidate()
          }}
          disabled={livenessIsLoading || isValidating}
          loading={livenessIsLoading || isValidating}
        >
          Atualizar Lista
        </Button>
        <FetchData
          hasData={!!livenessListData}
          error={livenessListError}
          isLoading={livenessIsLoading || isValidating}
        >
          <Collapse accordion items={items} />
        </FetchData>
      </div>
      {contextHolder}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LivenessView)
