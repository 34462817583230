import { Tag } from 'antd'
import Label from 'components/atomic/atoms/label'

import { CollapseLabelItemProps } from './types'

import './styles.css'

const CollapseLabelItem = ({
  label,
  tagColor,
  tagLabel
}: CollapseLabelItemProps) => 
  <div className="liveness-history-item">
    <Label label={label} />
    <Tag color={tagColor}>{tagLabel}</Tag>
  </div>

export default CollapseLabelItem