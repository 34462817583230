export enum LivenessStatus {
    Expired = 'EXPIRED',
    Failed = 'FAILED',
    Sucess = 'SUCCESS',
    InProgress = 'IN PROGRESS' ,
    OCR = 'OCR' ,
    Wainting = 'WAITING',
    Analysis = 'ANALYSIS', 
}

export enum LivenessErrorCounts {
    NoError,
    OneError,
    TwoErros,
    ThreeErros
}