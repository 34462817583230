export enum DateTimeZones {
    UTC = 'UTC',
    saoPaulo = 'America/Sao_Paulo'
}

export enum DateFormatTypes {
    ISO = 'YYYY-MM-DD',
    ISOTime = 'YYYY-MM-DDTHH:mm:ssZ',
    dateHourMinutes = 'DD/MM/YYYY HH:mm'
}
