import { call, put } from 'redux-saga/effects'
import { type PayloadAction } from 'typesafe-actions'

import { generateLivenessFailure, generateLivenessSuccess, getLivenessListFailure, getLivenessListSuccess } from './actions'
import { type Liveness as LivenessType, type LivenessTypes } from './types'
import Liveness, { type LivenessListData } from '../../../apis/Liveness'

export interface NewLivenessProps {
  bookingId: string
}

export interface LivenessListProps {
  bookingId?: string
  userId?: string
}

export function * generateLiveness (action: PayloadAction<LivenessTypes, NewLivenessProps>): Generator {
  try {
    const response = yield call(Liveness.newLiveness, action.payload.bookingId)
    yield put(generateLivenessSuccess({ ...(response as LivenessType) }))
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    console.warn('[generateLiveness] ERROR:', err)
    let errorMessage: string = 'Ocorreu um erro, tente novamente mais tarde!'
    if (err instanceof Error) errorMessage = err.message
    yield put(generateLivenessFailure({ errorMessage, status: err?.response?.status }))
  }
}

export function * getLivenessList (action: PayloadAction<LivenessTypes, LivenessListProps>): Generator {
  try {
    const response: any = yield call(Liveness.getLivenessList, {
      bookingId: action.payload.bookingId,
      userId: action.payload.userId
    })

    const livenessList: LivenessListData = {
      livenessList: response.livenessList ? response.livenessList : []
    }

    yield put(getLivenessListSuccess(livenessList.livenessList))
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    console.warn('[getLivenessList] ERROR: ', err.data)
    let errorMessage: string = 'Ocorreu um erro, tente novamente mais tarde!'
    if (err instanceof Error) errorMessage = err.message
    yield put(getLivenessListFailure({ message:  errorMessage, status: err.response.status }))
  }
}
